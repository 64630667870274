var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col atom-partner-column" }, [
    _c("a", { staticClass: "atom-partner", attrs: { href: "" + _vm.link } }, [
      _c("img", { attrs: { src: "" + _vm.image } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }