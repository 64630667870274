<template>
  <div :id="`${projectId}`" class="atom-projects">
    <a v-if="buttonUrl" class="link-project" :href="buttonUrl"></a>
    <p v-if="title" class="title" v-html="title"></p>
    <AtomIcon icon="angle-right" />
    <AtomButtonLink
      v-if="buttonUrl"
      :label="`${buttonText}`"
      :href="`${buttonUrl}`"
      icon="angle-right"
    />
  </div>
</template>

<script>
import AtomButtonLink from "../common/AtomButtonLink";
import AtomButton from "../common/AtomButton";
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";

export default {
  name: "AtomProjects",
  components: {
    AtomIcon,
    AtomButtonLink,
  },
  props: {
    title: {
      required: false,
      type: String,
      default: "",
    },
    buttonText: {
      required: false,
      type: String,
      default: "",
    },
    buttonUrl: {
      required: false,
      type: String,
      default: "javascript:void(0)",
    },
    projectId: {
      required: false,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
  },
};
</script>

<style lang="scss">
.atom-projects {
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
  background: $white;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  gap: 1rem;
  transition: all 0.3s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    margin-bottom: 16px;
    padding: 8px 16px;
    gap: 16px;
    white-space: initial;
  }
  .icon {
    font-size: 1.5rem !important;
    @media only screen and (max-width: 767px) {
      font-size: 21px !important;
    }
  }
  .link-project {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  .title {
    letter-spacing: -0.025rem;
    transition: all 0.3s ease-in-out 0s;
    @include font-main(1rem, $black, $regular, 1.625rem);
    @media only screen and (max-width: 767px) {
      @include font-main(16px, $black, $regular, 24px);
    }
  }
  .link-atom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    padding: 0.2rem 1rem;
    letter-spacing: 0.02rem;
    border: 1px solid $main-color;
    @include font-main(0.875rem, $white, $medium, 1.25rem);
    @media only screen and (max-width: 767px) {
      padding: 4px 16px;
      @include font-main(14px, $white, $medium, 20px);
    }
    span {
      @include font-main(0.875rem, $white, $medium, 0.875rem);
      @media only screen and (max-width: 767px) {
        @include font-main(14px, $white, $medium, 14px);
      }
    }
    .icon {
      margin-left: -0.5rem;
      margin-top: -0.1rem;
    }
  }
  &:hover {
    background: $main-color;
    .title {
      color: $white;
    }
    .link-atom {
      opacity: 1;
      .icon {
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
