var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-creator" },
    [
      _c(
        "MoleculeCreator",
        _vm._l(_vm.creatorData, function (creator, index) {
          return _c("AtomCreator", {
            key: index,
            attrs: {
              image: _vm.resourceUrl(creator.image),
              name: creator.name,
              urlLinkedin: creator.urlLinkedin,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }