<template>
  <div>
    <div v-if="!section || !enabled" />
    <SectionMapLocations
      v-else-if="section === 'Brochure' && portfolioPageAsMap"
      :data="data"
      :hash="hash"
      :order-no="orderNo"
    />
    <SectionHero
      v-else-if="section === 'Hero'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :extra="extra"
    />
    <SectionProjectsVideoBackground
      v-else-if="section === 'ProjectsVideoBackground'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :extra="extra"
    />
    <SectionPortfolioMap
      v-else-if="section === 'PortfolioMap'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :title="title"
      :subtitle="subtitle"
    />
    <SectionVision
      v-else-if="section === 'Vision'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :title="title"
      :subtitle="subtitle"
    />
    <SectionWhy
      v-else-if="section === 'Why'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :title="title"
      :subtitle="subtitle"
    />
    <SectionCreators
      v-else-if="section === 'Creators'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :title="title"
      :subtitle="subtitle"
    />
    <SectionTeam
      v-else-if="section === 'Team'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :title="title"
      :subtitle="subtitle"
    />
    <SectionPartners
      v-else-if="section === 'Partners'"
      :hash="hash"
      :data="data"
      :order-no="orderNo"
      :title="title"
      :subtitle="subtitle"
    />
  </div>
</template>

<script>
import SectionHero from "./section/SectionHero";
import SectionPortfolioMap from "./section/SectionPortfolioMap";
import SectionVision from "./section/SectionVision";
import SectionWhy from "./section/SectionWhy";
import SectionCreators from "./section/SectionCreators";
import SectionTeam from "./section/SectionTeam";
import SectionPartners from "./section/SectionPartners";
import SectionMapLocations from "@/views/portfolio/section/SectionMapLocations.vue";
import SectionProjectsVideoBackground from "@/views/portfolio/section/SectionProjectsVideoBackground.vue";

export default {
  name: "SectionSelector",
  components: {
    SectionProjectsVideoBackground,
    SectionMapLocations,
    SectionHero,
    SectionPortfolioMap,
    SectionVision,
    SectionWhy,
    SectionCreators,
    SectionTeam,
    SectionPartners,
  },
  props: {
    section: {
      required: true,
      type: String,
      default: "",
    },
    data: {
      required: true,
      type: [Object, Array],
      default: function () {
        return {};
      },
    },
    extra: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
    subtitle: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    portfolioPageAsMap() {
      return this.$route.meta?.page?.meta?.portfolioPageAsMap || false;
    },
  },
};
</script>
