<template>
  <div
    class="section section-projects-video-background"
    :class="{
      [`section${orderNo}`]: true,
    }"
    :style="{
      'margin-top': `${orderNo === 1 ? headerHeight : 0}px`,
      height: `calc(${windowHeight}px - ${headerHeight}px)`,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div
      class="title-top"
      v-if="data.heading && data.heading.length > 0 && data.welcomeMessage"
    >
      <h1 id="title-change" v-if="data.heading && data.heading.length > 0">
        {{ data.heading[0] }}
      </h1>
      <p
        v-if="data.welcomeMessage"
        class="line-text"
        v-html="data.welcomeMessage"
      />
    </div>
    <div
      class="background-opacity"
      v-if="hasBackgroundOverlay"
      :style="{ background: `rgba(0,0,0,${overlayOpacity})` }"
    ></div>
    <AtomProjectSliderBackground
      background-id=""
      active-background=""
      :video="data.video"
      :image="data.image"
    />
    <MoleculeProjectsAndCities :cities="cities" :projects="projects" />
  </div>
</template>

<script>
import AtomProjectSliderBackground from "@/components/atoms/portfolio/AtomProjectSliderBackground.vue";
import MoleculeProjectsAndCities from "@/components/molecules/portfolio/MoleculeProjectsAndCities.vue";

export default {
  name: "SectionProjectsVideoBackground",
  components: {
    MoleculeProjectsAndCities,
    AtomProjectSliderBackground,
  },
  props: {
    href: {
      required: false,
      type: String,
      default: "javascript:void(0)",
    },
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    extra: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      headerHeight: 0,
      interval: null,
    };
  },
  computed: {
    cities() {
      return this.data?.cities || [];
    },
    projects() {
      return this.data?.projects || [];
    },
    hasBackgroundOverlay() {
      return this.data.hasBackgroundOverlay;
    },
    overlayOpacity() {
      return this.data.backgroundOverlayOpacity || 0.3;
    },
  },
  mounted() {
    this.loopChangeHeaderText(1);
    window.addEventListener("resize", this.resizeListener);
    this.headerHeight = document.getElementById("header-project")
      ? document.getElementById("header-project").clientHeight
      : 0;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
    clearInterval(this.interval);
  },
  methods: {
    loopChangeHeaderText(start) {
      if (this.data.heading && this.data.heading.length > 0) {
        let titleText = this.data.heading;
        let i = start;
        this.interval = window.setInterval(function () {
          document.querySelector("#title-change").style.opacity = 0;
          setTimeout(function () {
            document.querySelector("#title-change").textContent = titleText[i];
            document.querySelector("#title-change").style.opacity = 1;
          }, 300);
          i = ++i % titleText.length;
        }, 5600);
      }
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
      this.headerHeight = document.getElementById("header-project")
        ? document.getElementById("header-project").clientHeight
        : 0;
    },
  },
};
</script>

<style lang="scss">
.section-projects-video-background {
  position: relative;
  overflow: hidden;
  .navigation-target {
    position: absolute;
    top: 0;
    left: 0;
  }
  .title-top {
    position: absolute;
    top: 10rem;
    left: 1.25rem;
    z-index: 9;
    max-width: 74rem;
    @media only screen and (max-width: 1000px) {
      max-width: 69rem;
    }
    @media only screen and (max-width: 767px) {
      max-width: 72rem;
      bottom: initial;
      top: 70px;
      left: 0;
      padding: 0 20px;
    }
    @media only screen and (max-width: 600px) {
      max-width: 100%;
    }
    .line-text {
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: $light;
      position: relative;
      padding-left: 6.25rem;
      margin-bottom: 1.875rem;
      color: $white;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        padding: 25px 0 0 0;
        margin-bottom: 20px;
      }
      b {
        font-size: 1.125rem;
        line-height: 1.375rem;
        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        width: 5.688rem;
        height: 1px;
        background: $white;
        top: 50%;
        left: 0;
        margin-top: -0.5px;
        @media only screen and (max-width: 767px) {
          top: 0;
          margin-top: 0;
          width: 91px;
        }
      }
    }
    h1 {
      font-size: 3.938rem;
      line-height: 4.875rem;
      font-weight: $regular;
      margin-bottom: 1.25rem;
      color: $white;
      transition: all 0.3s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 39px;
        margin-bottom: 20px;
      }
    }
  }
  .background-opacity {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
}
</style>
