var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-partner" },
    [
      _c(
        "MoleculePartner",
        _vm._l(_vm.partnerData, function (partner, index) {
          return _c("AtomPartner", {
            key: index,
            attrs: {
              image: _vm.resourceUrl(partner.image),
              link: partner.link,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }