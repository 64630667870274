var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-project-portfolio-padding" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "lazyload",
            rawName: "v-lazyload",
            value: { handle: _vm.handleLazyLoad, url: _vm.resourceUrl },
            expression: "{handle: handleLazyLoad, url: resourceUrl}",
          },
        ],
        class: { "atom-project-portfolio": true, active: _vm.isActive },
        style: { "background-image": "url(" + _vm.resourceUrl + ")" },
        attrs: { id: "" + _vm.projectId },
        on: { click: _vm.handleClick },
      },
      [
        _c(
          "div",
          { staticClass: "atom-project-portfolio-gradient" },
          [
            _c("h3", [
              _vm._v(_vm._s(_vm.title) + " "),
              _c("br"),
              _vm._v(_vm._s(_vm.subtitle)),
            ]),
            _c("AtomVerticalLine", { attrs: { color: "#fff" } }),
            _c("AtomContentText", {
              attrs: { color: "#fff", text: "" + _vm.text },
            }),
            _c("AtomButtonLink", {
              attrs: {
                label: "Learn more",
                icon: "right",
                href: "" + _vm.href,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }