<template>
  <div class="row portfolio-map">
    <div class="col col-xs-12 col-4 column-portfolio">
      <div class="organism-project-portfolio">
        <MoleculeProjectPortfolio>
          <AtomProjectPortfolio v-for="(portfolio, index) in portfolioData.projects"
                                :active-project="activeProject"
                                :tab-id="`item-${index}`"
                                :project-id="portfolio.id"
                                :image="portfolio.image"
                                :title="portfolio.title"
                                :subtitle="portfolio.subtitle"
                                :text="portfolio.text"
                                :href="portfolio.href"
                                :key="index" @change="handleThumbChange" />
        </MoleculeProjectPortfolio>
      </div>
    </div>
    <div class="col col-xs-12 col-8 column-portfolio">
      <MoleculeGoogleMap ref="map" :map-config="portfolioData.map" />
    </div>
  </div>
</template>

<script>
import MoleculeProjectPortfolio from "../../molecules/portfolio/MoleculeProjectPortfolio";
import AtomProjectPortfolio from "../../atoms/portfolio/AtomProjectPortfolio";
import MoleculeGoogleMap from "../../molecules/common/MoleculeGoogleMap";
export default {
  name: "OrganismProjectPortfolio",
  components: {MoleculeGoogleMap, AtomProjectPortfolio, MoleculeProjectPortfolio},
  props: {
    portfolioData: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      activeProject: 'item-0',
    }
  },
  methods: {
    handleThumbChange(tabData) {
      this.activeProject = tabData.tabId;
      this.$refs.map.triggerMarkerById(tabData.projectId);
    }
  }
}
</script>

<style lang="scss">
  .portfolio-map {
    @media only screen and (max-width: 767px) {
      padding-bottom: 120px;
      overflow: hidden;
    }
    .column-portfolio {
      height: 61.563rem;
      overflow-y: auto;
      @media only screen and (max-width: 767px) {
        height: auto;
        position: static !important;
      }
      .organism-project-portfolio {
        display: flex;
        flex-direction: column;
        height: 100%;
        @media only screen and (max-width: 767px) {
          display: block;
          position: absolute;
          bottom: 0;
          flex-direction: initial;
          width: 100%;
          height: auto;
        }
      }
      .atom-vertical-line {
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
      .atom-content-text {
        p {
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }
  }
</style>