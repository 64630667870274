var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atom-title-and-description" },
    [
      _c("AtomIcon", { attrs: { icon: _vm.icon } }),
      _vm.animation
        ? _c(
            "h4",
            {
              ref: "animationObservableTarget",
              staticClass: "value-animation",
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.animationNumber) +
                  " " +
                  _vm._s(_vm.title) +
                  "\n  "
              ),
            ]
          )
        : _c("h4", [_vm._v(_vm._s(_vm.title))]),
      _c("p", [_vm._v(_vm._s(_vm.description))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }