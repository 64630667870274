<template>
  <MoleculeContentSlider>
      <AtomContentSlider v-for="(sliderContent, index) in sliderContentData" :key="index">
        <p>{{sliderContent.title}}</p>
        <span class="verticale-line-white"></span>
        <p v-for="(line, index) in sliderContent.lines" :key="index">{{line}}</p>
      </AtomContentSlider>
  </MoleculeContentSlider>
</template>

<script>
import AtomContentSlider from "../../atoms/common/slider/AtomContentSlider";
import MoleculeContentSlider from "../../molecules/common/MoleculeContentSlider";
export default {
  name: "OrganismContentSlider",
  components: {MoleculeContentSlider, AtomContentSlider},
  props: {
    sliderContentData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  }
}
</script>

<style lang="scss">

</style>