<template>
  <div class="organism-creator">
    <MoleculeCreator>
      <AtomCreator v-for="(creator, index) in creatorData" :image="resourceUrl(creator.image)" :name="creator.name" :urlLinkedin="creator.urlLinkedin" :key="index"  />
    </MoleculeCreator>
  </div>
</template>

<script>
import MoleculeCreator from "../../molecules/portfolio/MoleculeCreator";
import AtomCreator from "../../atoms/portfolio/AtomCreator";
export default {
  name: "OrganismCreator",
  components: {AtomCreator, MoleculeCreator},
  props: {
    creatorData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .organism-creator {
    display: flex;
    flex-direction: column;
    flex: 100%;
  }
</style>