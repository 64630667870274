<template>
  <div class="molecule-project-portfolio">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeProjectPortfolio"
}
</script>

<style lang="scss" scoped>
  .molecule-project-portfolio {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 100%;
    @media only screen and (max-width: 767px) {
      overflow: hidden;
      overflow-x: auto;
      display: block;
      width: 100%;
      white-space: nowrap;
    }
  }
</style>