<template>
  <div class="atom-title-and-description">
    <AtomIcon :icon="icon" />
    <h4
      v-if="animation"
      class="value-animation"
      ref="animationObservableTarget"
    >
      {{ animationNumber }} {{ title }}
    </h4>
    <h4 v-else>{{ title }}</h4>
    <p>{{ description }}</p>
  </div>
</template>

<script>
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";

export default {
  name: "AtomTitleAndDescription",
  components: { AtomIcon },
  props: {
    icon: {
      required: false,
      type: String,
      default: "",
    },
    title: {
      required: true,
      type: String,
      default: "",
    },
    animationTitle: {
      required: true,
      type: String,
      default: "",
    },
    animation: {
      required: false,
      type: Boolean,
      default: false,
    },
    description: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      animationNumber: 0,
    };
  },
  mounted() {
    if (this.animation) {
      const options = {
        root: null, // Use the viewport as the root element
        rootMargin: "0px", // No margin around the root element
        threshold: 0.5, // Trigger when at least 50% of the element is visible
      };
      const observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );

      // Start observing the section
      observer.observe(this.$refs.animationObservableTarget);
    }
  },
  methods: {
    animateNumberIncrement() {
      const duration = 2000;
      const startTime = performance.now();
      const step = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = elapsedTime / duration;
        const targetNumber = parseInt(this.animationTitle) || 0;
        const currentNumber = Math.min(targetNumber * progress, targetNumber);
        this.animationNumber = Math.floor(currentNumber);
        if (elapsedTime < duration) {
          requestAnimationFrame(step);
        }
      };
      requestAnimationFrame(step);
    },
    handleIntersection(entries, observer) {
      // The callback function to handle the intersection
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Section entered the viewport
          this.animationNumber = 0;
          this.animateNumberIncrement();
        } else {
          // Section left the viewport
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-title-and-description {
  flex: 0 0 50%;
  padding: 0 1.75rem;
  margin: 1.875rem 0;
  @media only screen and (max-width: 767px) {
    padding: 0;
    flex: 1 0 100%;
    margin: 15px 0;
  }
  .icon {
    font-size: 2.1rem !important;
    color: $white !important;
    margin-bottom: 1rem;
    display: block;
    @media only screen and (max-width: 767px) {
      font-size: 23px !important;
      margin-bottom: 10px;
    }
    &:before {
      margin: 0;
    }
  }
  h4 {
    font-weight: $bold;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: $main-color;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 767px) {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 5px;
    }
  }
  p {
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: $white;
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 29px;
    }
  }
}
</style>
