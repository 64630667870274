<template>
  <div class="molecule-projects-and-cities">
    <div class="row molecule-tab-city">
      <AtomTabCity
        class="col col-auto"
        v-for="(c, idx1) in cities"
        :key="idx1"
        :city="c.city"
        :title="c.title"
        :image="c.image"
        :url="c.url"
        :projects="c.projects"
      />
    </div>
  </div>
</template>

<script>
import AtomTabCity from "@/components/atoms/portfolio/AtomTabCity.vue";
import AtomProjects from "@/components/atoms/portfolio/AtomProjects.vue";

export default {
  name: "MoleculeProjectsAndCities",
  components: { AtomTabCity },
  props: {
    cities: {
      required: true,
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.molecule-projects-and-cities {
  .molecule-tab-city {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 40% 1.25rem 1.25rem;
    gap: 1.25rem;
    z-index: 9;
    width: 100%;
    overflow-x: auto;
    @media only screen and (max-width: 767px) {
      display: block;
      white-space: nowrap;
      padding: 100% 20px 10px;
    }
  }
}
</style>
