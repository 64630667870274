<template>
  <div class="molecule-content-slider carousel-box">
    <div class="owl-carousel owl-theme" ref="carousel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoleculeContentSlider",
  props: {
    carouselOpts: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      instance: null,
    };
  },
  mounted() {
    this.initCarousel();
  },
  beforeUpdate() {
    this.destroyCarousel();
  },
  updated() {
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      this.instance = window.$(this.$refs.carousel).owlCarousel({
        items: 1,
        nav: true,
        dots: true,
        margin: 20,
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        ...this.carouselOpts,
      });
    },
    destroyCarousel() {
      this.instance.trigger("destroy.owl.carousel");
      this.instance = null;
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/carousel";
.molecule-content-slider {
  &.carousel-box {
    position: relative;
    width: 59.375rem;
    max-width: 100%;
    padding: 4.688rem 4.375rem 8.75rem;
    background: $main-color;
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 30px 20px 115px;
      margin-top: 40px;
    }
    .owl-carousel {
      position: static;
      .owl-nav {
        position: absolute;
        bottom: 3.125rem;
        left: 4.375rem;
        @media only screen and (max-width: 767px) {
          bottom: 25px;
          left: 20px;
        }
      }
      .owl-dots {
        position: absolute;
        bottom: 3.75rem;
        right: 4.375rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 767px) {
          display: none;
        }
        &.disabled {
          display: none;
        }
      }
    }
  }
}
</style>
