var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-map-locations",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c(
        "div",
        { staticClass: "top-title", attrs: { id: "top-title-locations" } },
        [
          _c("AtomTitle", {
            attrs: {
              color: "#000",
              color2: "#000",
              title: _vm.data.title,
              subtitle: _vm.data.subtitle,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "location-element",
          style: {
            "min-height":
              "calc(" +
              _vm.windowHeight +
              "px - " +
              _vm.sidebarLocations +
              "px)",
          },
        },
        [
          _vm.mapConfig
            ? _c("OrganismMainLocations", {
                attrs: { "map-config": _vm.mapConfig },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }