var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-projects-and-cities" }, [
    _c(
      "div",
      { staticClass: "row molecule-tab-city" },
      _vm._l(_vm.cities, function (c, idx1) {
        return _c("AtomTabCity", {
          key: idx1,
          staticClass: "col col-auto",
          attrs: {
            city: c.city,
            title: c.title,
            image: c.image,
            url: c.url,
            projects: c.projects,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }