<template>
  <div :id="`tab-${city}`" class="parent-atom-tab-city">
    <div class="atom-tab-city">
      <a v-if="url" class="url-link" :href="url"></a>
      <img :src="resourceUrl('images/placeholder16x9.jpg')" alt="Placeholder" />
      <div
        class="background-image"
        :style="{ 'background-image': `url(${resourceUrl(image)})` }"
      ></div>
      <div class="background-gradient"></div>
      <div v-if="title" class="city-name">
        <h6 v-html="title"></h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtomTabCity",
  components: {},
  props: {
    projects: {
      required: true,
      type: Array,
      default: () => {
        return [];
      },
    },
    city: {
      required: false,
      type: String,
      default: "",
    },
    url: {
      required: false,
      type: String,
      default: "javascript:void(0)",
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
    image: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    index() {
      return this.$vnode.data.key;
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.parent-atom-tab-city {
  position: relative;
  @media only screen and (max-width: 767px) {
    display: inline-block !important;
    width: 250px;
    margin-right: 20px;
  }
  .parent-atom-projects {
    position: absolute;
    left: 0;
    opacity: 0;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease-in-out 0s;
  }
  .atom-tab-city {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .background-image {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out 0s;
    }
    img {
      display: block;
      width: 100%;
    }
    .background-gradient {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        $blackOpacity00 30%,
        $blackOpacity09 85%
      );
      z-index: 3;
      transition: all 0.3s ease-in-out 0s;
    }
    .city-name {
      position: absolute;
      bottom: 0.875rem;
      width: 100%;
      padding: 0 1.5rem;
      z-index: 5;
      text-align: center;
      transition: all 0.3s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        bottom: 10px;
        padding: 0 10px;
      }
      h6 {
        color: $white;
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 24px;
        }
        b {
          font-size: 1.375rem;
          line-height: 1.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
    .url-link {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    &:hover {
      .background-image {
        width: 110%;
        height: 110%;
        @media only screen and (max-width: 1000px) {
          width: 100%;
          height: 100%;
        }
      }
      .background-gradient {
        background: $blackOpacity06;
        @media only screen and (max-width: 1000px) {
          background: linear-gradient(
            180deg,
            $blackOpacity00 30%,
            $blackOpacity09 85%
          );
        }
      }
      .city-name {
        bottom: 50%;
        transform: translateY(50%);
        @media only screen and (max-width: 1000px) {
          bottom: 0.875rem;
          transform: none;
        }
        @media only screen and (max-width: 767px) {
          bottom: 10px;
        }
      }
    }
  }
  &.active {
    .parent-atom-projects {
      opacity: 1 !important;
      bottom: 100% !important;
    }
    .atom-tab-city {
      .background-image {
        width: 110% !important;
        height: 110% !important;
      }
      .background-gradient {
        background: $blackOpacity06 !important;
      }
      .city-name {
        bottom: 50% !important;
        transform: translateY(50%) !important;
      }
    }
  }
}
</style>
