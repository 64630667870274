var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-hero": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
      style: { "min-height": _vm.windowHeight + "px" },
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c("div", { staticClass: "gradient" }),
      _c(
        "div",
        { staticClass: "title-bottom" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.heading))]),
          _c("p", {
            staticClass: "line-text",
            domProps: { innerHTML: _vm._s(_vm.welcome) },
          }),
          _c("AtomButtonLink", {
            attrs: {
              label: "Go to " + _vm.sliderTitleLabel,
              href: _vm.sliderButtonUrl,
              icon: "right",
            },
          }),
          _c("br"),
          _c(
            "span",
            {
              staticClass: "scroll-element-desktop",
              on: { click: _vm.goToNextSection },
            },
            [
              _c(
                "span",
                { staticClass: "icon-scroll-down" },
                [
                  _c("AtomIcon", {
                    attrs: { icon: "angle-down", size: 17, color: "#fff" },
                  }),
                ],
                1
              ),
              _vm._v("\n      Scroll down for more information\n    "),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "scroll-element-mobile",
          on: { click: _vm.goToNextSection },
        },
        [
          _c(
            "span",
            { staticClass: "icon-scroll-down" },
            [
              _c("AtomIcon", {
                attrs: { icon: "angle-down", size: 17, color: "#fff" },
              }),
            ],
            1
          ),
        ]
      ),
      _c("OrganismProjectSlider", {
        attrs: { "slider-data": _vm.data },
        on: { "thumb-change": _vm.handleThumbChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }