<template>
 <div class="organism-partner">
   <MoleculePartner>
     <AtomPartner v-for="(partner, index) in partnerData" :image="resourceUrl(partner.image)" :link="partner.link" :key="index" />
   </MoleculePartner>
 </div>
</template>

<script>
import MoleculePartner from "../../molecules/portfolio/MoleculePartner";
import AtomPartner from "../../atoms/portfolio/AtomPartner";
export default {
  name: "OrganismPartner",
  components: {AtomPartner, MoleculePartner},
  props: {
    partnerData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .organism-partner {
    display: flex;
    flex-direction: column;
    flex: 100%;
  }
</style>