var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-creators": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c(
        "div",
        { staticClass: "top-title" },
        [
          _c("AtomTitle", {
            attrs: {
              color: "#fff",
              color2: "#fff",
              title: _vm.titleString,
              subtitle: _vm.subtitleString,
            },
          }),
          _c("AtomVerticalLine"),
          _c("span", { staticClass: "info-title" }, [
            _vm._v("Main Shareholders"),
          ]),
        ],
        1
      ),
      _c("OrganismCreator", { attrs: { "creator-data": _vm.data } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }