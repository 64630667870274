var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parent-atom-tab-city", attrs: { id: "tab-" + _vm.city } },
    [
      _c("div", { staticClass: "atom-tab-city" }, [
        _vm.url
          ? _c("a", { staticClass: "url-link", attrs: { href: _vm.url } })
          : _vm._e(),
        _c("img", {
          attrs: {
            src: _vm.resourceUrl("images/placeholder16x9.jpg"),
            alt: "Placeholder",
          },
        }),
        _c("div", {
          staticClass: "background-image",
          style: {
            "background-image": "url(" + _vm.resourceUrl(_vm.image) + ")",
          },
        }),
        _c("div", { staticClass: "background-gradient" }),
        _vm.title
          ? _c("div", { staticClass: "city-name" }, [
              _c("h6", { domProps: { innerHTML: _vm._s(_vm.title) } }),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }