var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row portfolio-map" }, [
    _c("div", { staticClass: "col col-xs-12 col-4 column-portfolio" }, [
      _c(
        "div",
        { staticClass: "organism-project-portfolio" },
        [
          _c(
            "MoleculeProjectPortfolio",
            _vm._l(_vm.portfolioData.projects, function (portfolio, index) {
              return _c("AtomProjectPortfolio", {
                key: index,
                attrs: {
                  "active-project": _vm.activeProject,
                  "tab-id": "item-" + index,
                  "project-id": portfolio.id,
                  image: portfolio.image,
                  title: portfolio.title,
                  subtitle: portfolio.subtitle,
                  text: portfolio.text,
                  href: portfolio.href,
                },
                on: { change: _vm.handleThumbChange },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "col col-xs-12 col-8 column-portfolio" },
      [
        _c("MoleculeGoogleMap", {
          ref: "map",
          attrs: { "map-config": _vm.portfolioData.map },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }