<template>
  <div
    :class="{
      section: true,
      'section-team': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle
        color="#fff"
        color2="#fff"
        :title="titleString"
        :subtitle="subtitleString"
      />
      <AtomVerticalLine />
    </div>
    <OrganismTeam :team-data="data.contacts" />
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import AtomVerticalLine from "../../../components/atoms/common/AtomVerticalLine";
import OrganismTeam from "../../../components/organisms/portfolio/OrganismTeam";
export default {
  name: "SectionTeam",
  components: { OrganismTeam, AtomVerticalLine, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  computed: {
    titleString() {
      return this.data.title || "The";
    },
    subtitleString() {
      return this.data.subtitle || "Team";
    },
  },
};
</script>

<style lang="scss">
.section-team {
  padding: 6.25rem 1.25rem 5rem;
  background: $black;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding: 30px 20px;
  }
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .top-title {
    margin-bottom: 3.75rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
    .atom-title {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      h2 {
        @include font-main(4rem, $white !important, $light, 4.125rem);
        @media only screen and (max-width: 767px) {
          font-size: 32px;
          line-height: 39px;
        }
        b {
          font-size: 4rem;
          line-height: 4.125rem;
          @media only screen and (max-width: 767px) {
            font-size: 32px;
            line-height: 36px;
          }
        }
      }
      h3 {
        @include font-main(4rem, $white !important, $medium, 4.125rem);
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 19px;
        }
        b {
          font-size: 4rem;
          line-height: 4.125rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
}
</style>
