<template>
  <div
    class="section section-vision"
    :class="{ [`section${orderNo}`]: true }"
    :style="{
      'background-image': `url(${resourceUrl(data.backgroundImage)})`,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div v-if="data.backgroundImage" class="background-opacity"></div>
    <div class="top-title">
      <AtomTitle
        color="#fff"
        color2="#fff"
        :title="titleString"
        :subtitle="subtitleString"
      />
    </div>
    <div class="effect-building" v-if="!data.noAnimation">
      <img
        :src="require('../../../assets/images/buildings.svg').default"
        alt="SVG Animate"
      />
      <div class="black-background"></div>
    </div>
    <div class="row bottom-elements align-items-center">
      <div class="col col-xs-12 col-6">
        <OrganismTitleAndDescription :vision-data="data.vision" />
      </div>
      <div class="col col-xs-12 col-6">
        <OrganismContentSlider :slider-content-data="data.slider">
        </OrganismContentSlider>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import OrganismTitleAndDescription from "../../../components/organisms/common/OrganismTitleAndDescription";
import OrganismContentSlider from "../../../components/organisms/common/OrganismContentSlider";
export default {
  name: "SectionVision",
  components: { OrganismContentSlider, OrganismTitleAndDescription, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          noAnimation: false,
          vision: [],
          slider: [],
        };
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
    subtitle: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    titleString() {
      return this.title || "The";
    },
    subtitleString() {
      return this.subtitle || "Vision";
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-vision {
  padding: 6.25rem 0 3.125rem;
  background-color: $black;
  background-position: center center;
  background-size: cover;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  min-height: 56.25rem;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding: 30px 0;
  }
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .background-opacity {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .top-title {
    padding: 0 1.25rem;
    margin-bottom: 2.5rem;
    position: relative;
    z-index: 7;
    @media only screen and (max-width: 767px) {
      padding: 0 20px;
      margin-bottom: 60px;
    }
    .atom-title {
      h2 {
        @include font-main(4rem, $white !important, $light, 4.125rem);
        @media only screen and (max-width: 767px) {
          font-size: 32px;
          line-height: 39px;
        }
        b {
          font-size: 4rem;
          line-height: 4.125rem;
          @media only screen and (max-width: 767px) {
            font-size: 32px;
            line-height: 36px;
          }
        }
      }
      h3 {
        @include font-main(4rem, $white !important, $medium, 4.125rem);
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 19px;
        }
        b {
          font-size: 4rem;
          line-height: 4.125rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .effect-building {
    position: absolute;
    top: -10rem;
    left: -3.5rem;
    width: 100%;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      top: -186px;
      left: -102px;
      width: 1300px;
    }
    #building-svg {
      width: 100%;
    }
    .black-background {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 9;
      background: $black;
      width: 100%;
      animation: 7s ease 0s normal none infinite running building-effect;
    }
  }
  .bottom-elements {
    z-index: 8;
    padding: 0 1.25rem;
    margin: 0 -1.875rem 2rem;
    @media only screen and (max-width: 767px) {
      padding: 0 20px;
      margin: 0;
    }
    .col {
      padding: 0 1.875rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }
}
@keyframes building-effect {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
