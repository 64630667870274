var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "locations-map",
      style: {
        height:
          "calc(" + _vm.windowHeight + "px - " + _vm.sidebarLocations + "px)",
      },
    },
    [
      _c("MoleculeGoogleMap", {
        ref: "map",
        staticClass: "landing-map-container",
        style: {
          height:
            "calc(" + _vm.windowHeight + "px - " + _vm.sidebarLocations + "px)",
        },
        attrs: { "map-config": _vm.mapConfig },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }