<template>
  <div class="col atom-partner-column">
    <a class="atom-partner" :href="`${link}`">
      <img :src="`${image}`" />
    </a>
  </div>
</template>

<script>
export default {
  name: "AtomPartner",
  props: {
    image: {
      required: true,
      type: String
    },
    link: {
      required: true,
      type: String,
      default: 'javascript:void(0)'
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-partner-column {
    flex: 0 0 20%;
    margin-bottom: 3.125rem;
    padding: 0 1.563rem;
    @media only screen and (max-width: 767px) {
      flex: 1 0 50%;
      margin-bottom: 10px;
      padding: 0 5px;
    }
    .atom-partner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 12.5rem;
      background: #F7F7F7;
      @media only screen and (max-width: 767px) {
        height: 80px;
      }
      img {
        display: block;
        max-width: 14.063rem;
        max-height: 11.25rem;
        @media only screen and (max-width: 767px) {
          max-width: 65%;
          max-height: 70%;
        }
      }
    }
  }
</style>