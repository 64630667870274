<template>
  <div
    class="section section-map-locations"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" id="top-title-locations">
      <AtomTitle
        color="#000"
        color2="#000"
        :title="data.title"
        :subtitle="data.subtitle"
      />
    </div>
    <div
      class="location-element"
      :style="{
        'min-height': `calc(${windowHeight}px - ${sidebarLocations}px)`,
      }"
    >
      <OrganismMainLocations v-if="mapConfig" :map-config="mapConfig" />
    </div>
  </div>
</template>

<script>
import OrganismMainLocations from "@/components/organisms/portfolio/OrganismMainLocations.vue";
import AtomTitle from "@/components/atoms/common/AtomTitle.vue";

export default {
  name: "SectionMapLocations",
  components: { AtomTitle, OrganismMainLocations },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      sidebarLocations: 0,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeListener);
    this.sidebarLocations = document.getElementById("top-title-locations")
      ? document.getElementById("top-title-locations").clientHeight
      : 0;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
    this.sidebarLocations = document.getElementById("top-title-locations")
      ? document.getElementById("top-title-locations").clientHeight
      : 0;
  },
  computed: {
    mapConfig() {
      return this.data?.map || null;
    },
  },
  methods: {
    resizeListener(event) {
      this.windowHeight = window.innerHeight;
      this.sidebarLocations = document.getElementById("top-title-locations")
        ? document.getElementById("top-title-locations").clientHeight
        : 0;
    },
  },
};
</script>

<style lang="scss">
.section-map-locations {
  position: relative;
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .top-title {
    padding: 3.25rem 1.25rem 2.5rem;
    @media only screen and (max-width: 767px) {
      padding: 30px 20px 25px;
    }
    .atom-title {
      h2 {
        @include font-main(4rem, $black !important, $light, 4.125rem);
        @media only screen and (max-width: 767px) {
          font-size: 32px;
          line-height: 39px;
        }
        b {
          font-size: 4rem;
          line-height: 4.125rem;
          @media only screen and (max-width: 767px) {
            font-size: 32px;
            line-height: 36px;
          }
        }
      }
      h3 {
        @include font-main(4rem, $black !important, $medium, 4.125rem);
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 19px;
        }
        b {
          font-size: 4rem;
          line-height: 4.125rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .location-element {
    position: relative;
  }
  .static-marker {
    border-radius: 6.25rem;
    cursor: pointer;
    transition: padding 0.2s ease-in-out 0s,
      background-color 0.2s ease-in-out 0s;
    padding: 0.313rem 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    @media only screen and (max-width: 767px) {
      padding: 5px 10px;
    }
    &:after {
      border-width: 0.438rem;
      margin-left: -0.438rem;
      margin-top: -0.063rem;
      border-top-color: $white;
      @media only screen and (max-width: 767px) {
        border-width: 8px;
        margin-left: -7px;
      }
    }
    .logo-pin {
      margin-right: 0.313rem;
      width: 1.125rem;
      @media only screen and (max-width: 767px) {
        margin-right: 5px;
        width: 18px;
      }
    }
    .title-marker {
      padding: 0;
      border-radius: 6.25rem;
      font-weight: $regular;
      font-size: 0.875rem;
      line-height: 1.063rem;
      white-space: nowrap;
      transition: all 0.2s ease-in-out 0s;
      color: $black;
      @media only screen and (max-width: 767px) {
        padding: 0;
        font-size: 14px;
        line-height: 19px;
      }

      b {
        font-weight: $regular;
        font-size: 0.875rem;
        line-height: 1.063rem;
        text-transform: none;
        display: inline-block;
        transition: all 0.2s ease-in-out 0s;
        color: $black;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    &:hover,
    &.active {
      background-color: $main-color;
      padding: 0.375rem 0.75rem;
      @media only screen and (max-width: 767px) {
        padding: 6px 12px;
      }
      &:after {
        border-top-color: $main-color;
      }
      .title-marker {
        color: $white;
        font-size: 1rem;
        line-height: 1.188rem;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 19px;
        }
        b {
          color: $white;
          font-size: 1rem;
          line-height: 1.188rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
}
</style>
