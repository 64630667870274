var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-vision",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj),
      style: {
        "background-image":
          "url(" + _vm.resourceUrl(_vm.data.backgroundImage) + ")",
      },
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _vm.data.backgroundImage
        ? _c("div", { staticClass: "background-opacity" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "top-title" },
        [
          _c("AtomTitle", {
            attrs: {
              color: "#fff",
              color2: "#fff",
              title: _vm.titleString,
              subtitle: _vm.subtitleString,
            },
          }),
        ],
        1
      ),
      !_vm.data.noAnimation
        ? _c("div", { staticClass: "effect-building" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/buildings.svg").default,
                alt: "SVG Animate",
              },
            }),
            _c("div", { staticClass: "black-background" }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row bottom-elements align-items-center" }, [
        _c(
          "div",
          { staticClass: "col col-xs-12 col-6" },
          [
            _c("OrganismTitleAndDescription", {
              attrs: { "vision-data": _vm.data.vision },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col col-xs-12 col-6" },
          [
            _c("OrganismContentSlider", {
              attrs: { "slider-content-data": _vm.data.slider },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }