<template>
  <div class="row molecule-creator">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeCreator"
}
</script>

<style lang="scss" scoped>
  .molecule-creator {
    margin: 0 -2.625rem;
  }
</style>