<template>
  <div :class="{section: true, 'section-hero': true, [`section${orderNo}`]: true}" :style="{'min-height': `${windowHeight}px`}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="gradient"></div>
    <div class="title-bottom">
      <h1>{{ heading }}</h1>
      <p class="line-text" v-html="welcome" />
      <AtomButtonLink :label="`Go to ${sliderTitleLabel}`" :href="sliderButtonUrl" icon="right" />
      <br/>
      <span class="scroll-element-desktop" @click="goToNextSection">
        <span class="icon-scroll-down">
          <AtomIcon icon="angle-down" :size="17" color="#fff" />
        </span>
        Scroll down for more information
      </span>
    </div>
    <span class="scroll-element-mobile" @click="goToNextSection">
      <span class="icon-scroll-down">
        <AtomIcon icon="angle-down" :size="17" color="#fff" />
      </span>
    </span>
    <OrganismProjectSlider :slider-data="data" @thumb-change="handleThumbChange"/>
  </div>
</template>

<script>
import AtomIcon from "../../../components/atoms/common/AtomIcon";
import AtomButtonLink from "../../../components/atoms/common/AtomButtonLink";
import OrganismProjectSlider from "../../../components/organisms/portfolio/OrganismProjectSlider";
import {convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId} from "../../../helpers/util";

export default {
  name: "SectionHero",
  components: {OrganismProjectSlider,AtomButtonLink, AtomIcon},
  props: {
    href: {
      required: false,
      type: String,
      default: "javascript:void(0)",
    },
    data: {
      required: true,
      type: Array,
      default: function () {
        return []
      },
    },
    extra: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  data() {
    return {
      activeThumb: 0,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
    }
  },
  computed: {
    sliderTitleLabel() {
      return `${this.data[this.activeThumb].title} ${this.data[this.activeThumb].subtitle}`;
    },
    sliderButtonUrl() {
      return this.data[this.activeThumb].href;
    },
    heading() {
      return this.extra.heading;
    },
    welcome() {
      return this.extra.welcomeMessage;
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.sections;
      if (sections && sections.length > 1) {
        const targetSection = sections[this.orderNo];
        const targetString = convertUCWordsOrCamelCaseToDashCase(targetSection.type);
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
    handleThumbChange(itemId) {
      const temp = itemId.split('-');
      this.activeThumb = parseInt(temp[1]);
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
    }
  }
}
</script>

<style lang="scss">
  .section-hero {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    .navigation-target {
      position: absolute;
      top: 0;
      left: 0;
    }
    .gradient {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      background: rgba(0,0,0,0.8);
    }
    .title-bottom {
      position: absolute;
      bottom: 6.25rem;
      left: 5.625rem;
      z-index: 9;
      max-width: 50rem;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
        bottom: initial;
        top: 175px;
        left: 0;
        padding: 0 20px;
      }
      .line-text {
        font-size: 1.125rem;
        line-height: 1.375rem;
        font-weight: $light;
        text-transform: uppercase;
        position: relative;
        padding-left: 6.25rem;
        margin-bottom: 1.875rem;
        color: $white;
        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 22px;
          padding: 25px 0 0 0;
          margin-bottom: 20px;
        }
        b {
          font-size: 1.125rem;
          line-height: 1.375rem;
          @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
        &:before {
          content: '';
          position: absolute;
          width: 5.688rem;
          height: 1px;
          background: $white;
          top: 50%;
          left: 0;
          margin-top: -0.5px;
          @media only screen and (max-width: 767px) {
            top: 0;
            margin-top: 0;
            width: 91px;
          }
        }
      }
      h1 {
        font-size: 3.938rem;
        line-height: 4.875rem;
        text-transform: uppercase;
        font-weight: $regular;
        margin-bottom: 1.25rem;
        color: $white;
        @media only screen and (max-width: 767px) {
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 20px;
        }
      }
      .link-atom {
        padding: 0.75rem 1.563rem;
        margin-bottom: 3.125rem;
        @media only screen and (max-width: 767px) {
          padding: 8px 20px;
          margin-bottom: 30px;
        }
        .icon {
          font-size: 1.25rem !important;
          margin-left: 0.625rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 12px !important;
            margin-left: 5px !important;
          }
        }
      }
    }
    .scroll-element-desktop {
      font-size: 0.75rem;
      line-height: 1.5rem;
      color: $white;
      cursor: pointer;
      @media only screen and (max-width: 767px) {
        display: none;
      }
      .icon-scroll-down {
        display: inline-block;
        vertical-align: middle;
        width: 1.688rem;
        height: 1.688rem;
        margin-right: 0.625rem;
        line-height: 1.6rem;
        text-align: center;
        border: 0.125rem solid rgba(255,255,255,0.1);
        border-radius: 1.688rem;
        .icon {
          font-size: 1.063rem !important;
        }
      }
    }
    .scroll-element-mobile {
      display: none;
      @media only screen and (max-width: 767px) {
        position: absolute;
        left: 50%;
        border: 1px solid $white;
        z-index: 9;
        cursor: pointer;
        display: flex;
        align-items: center;
        bottom: 30px;
        width: 46px;
        height: 46px;
        border-radius: 46px;
        margin-left: -23px;
        .icon {
          font-size: 32px !important;
        }
      }
    }
  }
</style>