<template>
  <div
    class="locations-map"
    :style="{
      height: `calc(${windowHeight}px - ${sidebarLocations}px)`,
    }"
  >
    <MoleculeGoogleMap
      class="landing-map-container"
      ref="map"
      :map-config="mapConfig"
      :style="{
        height: `calc(${windowHeight}px - ${sidebarLocations}px)`,
      }"
    />
  </div>
</template>

<script>
import MoleculeGoogleMap from "../../molecules/common/MoleculeGoogleMap";

export default {
  name: "OrganismMainLocations",
  components: {
    MoleculeGoogleMap,
  },
  props: {
    mapConfig: {
      required: true,
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      headerHeight: 0,
      sidebarLocations: 0,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeListener);
    this.headerHeight = document.getElementById("header-project")
      ? document.getElementById("header-project").clientHeight
      : 0;
    this.sidebarLocations = document.getElementById("top-title-locations")
      ? document.getElementById("top-title-locations").clientHeight
      : 0;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
      this.headerHeight = document.getElementById("header-project")
        ? document.getElementById("header-project").clientHeight
        : 0;
      this.sidebarLocations = document.getElementById("top-title-locations")
        ? document.getElementById("top-title-locations").clientHeight
        : 0;
    },
  },
};
</script>

<style lang="scss">
.locations-map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .organism-main-locations {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    background: $white;
    min-height: 5rem;
    @media only screen and (max-width: 767px) {
      min-height: 75px;
    }
    .info-text-sidebar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      z-index: 9;
      font-size: 1.5rem;
      text-align: center;
      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  .landing-map-container {
    @media only screen and (max-width: 767px) {
      height: 100%;
    }
  }
}
</style>
