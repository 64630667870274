var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-projects-video-background",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj),
      style: {
        "margin-top": (_vm.orderNo === 1 ? _vm.headerHeight : 0) + "px",
        height: "calc(" + _vm.windowHeight + "px - " + _vm.headerHeight + "px)",
      },
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _vm.data.heading && _vm.data.heading.length > 0 && _vm.data.welcomeMessage
        ? _c("div", { staticClass: "title-top" }, [
            _vm.data.heading && _vm.data.heading.length > 0
              ? _c("h1", { attrs: { id: "title-change" } }, [
                  _vm._v("\n      " + _vm._s(_vm.data.heading[0]) + "\n    "),
                ])
              : _vm._e(),
            _vm.data.welcomeMessage
              ? _c("p", {
                  staticClass: "line-text",
                  domProps: { innerHTML: _vm._s(_vm.data.welcomeMessage) },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.hasBackgroundOverlay
        ? _c("div", {
            staticClass: "background-opacity",
            style: { background: "rgba(0,0,0," + _vm.overlayOpacity + ")" },
          })
        : _vm._e(),
      _c("AtomProjectSliderBackground", {
        attrs: {
          "background-id": "",
          "active-background": "",
          video: _vm.data.video,
          image: _vm.data.image,
        },
      }),
      _c("MoleculeProjectsAndCities", {
        attrs: { cities: _vm.cities, projects: _vm.projects },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }