var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organsim-title-and-description" },
    [
      _c(
        "MoleculeTitleAndDescription",
        _vm._l(_vm.visionData, function (vision, index) {
          return _c("AtomTitleAndDescription", {
            key: index,
            attrs: {
              icon: vision.icon,
              title: vision.title,
              "animation-title": vision.animationTitle,
              description: vision.description,
              animation: vision.animation,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }