var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-partners": true,
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" },
      }),
      _c(
        "div",
        { staticClass: "top-title" },
        [
          _c("AtomTitle", {
            attrs: {
              color2: "#000",
              title: _vm.titleString,
              subtitle: _vm.subtitleString,
            },
          }),
          _c("AtomVerticalLine", { attrs: { color: "#2E284F" } }),
          _c("AtomContentText", {
            attrs: {
              color: "#000",
              text: "Growth at scale is possible through smart partnerships with innovative companies:",
            },
          }),
        ],
        1
      ),
      _c("OrganismPartner", { attrs: { "partner-data": _vm.data } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }