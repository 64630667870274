<template>
  <div class="page landing">
    <SectionSelector
      v-for="(section, key) in sections"
      :enabled="section.enabled"
      :hash="section.hashAnchor"
      :title="section.title"
      :subtitle="section.subtitle"
      :data="section.data"
      :extra="section.extra || {}"
      :section="section.type"
      :order-no="section.order_no"
      :key="key"
    />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";

const config = require("../../config").default;

export default {
  name: "PageLanding",
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `%s - ${this.meta.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.meta.description,
        },
      ],
    };
  },
  components: {
    SectionSelector,
  },
  computed: {
    meta() {
      return this.$route.meta?.page?.meta;
    },
    sectionsData() {
      return this.$route.meta?.page?.sections || [];
    },
  },
  data() {
    return {
      sections: [],
    };
  },
  created() {
    this.sortSections();
    const projectConstants = this.$store.getters.constants.project;
    this.$store.dispatch(
      projectConstants.withNamespace(
        projectConstants.action.LOAD_LANDLORD_DETAILS
      )
    );
  },
  methods: {
    sortSections() {
      this.sections = [...this.sectionsData].sort(
        (a, b) => a.order_no - b.order_no
      );
    },
  },
};
</script>

<style lang="scss">
.landing {
  .section {
    position: relative;

    .top-title {
      display: flex;
      flex-direction: column;
      flex: 100%;
    }
  }
}
</style>
