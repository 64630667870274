<template>
  <div class="row molecule-partner">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculePartner"
}
</script>

<style lang="scss" scoped>
  .molecule-partner {
    margin: 0 -1.563rem;
    @media only screen and (max-width: 767px) {
      margin: 0 -5px;
    }
  }
</style>