var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atom-projects", attrs: { id: "" + _vm.projectId } },
    [
      _vm.buttonUrl
        ? _c("a", {
            staticClass: "link-project",
            attrs: { href: _vm.buttonUrl },
          })
        : _vm._e(),
      _vm.title
        ? _c("p", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.title) },
          })
        : _vm._e(),
      _c("AtomIcon", { attrs: { icon: "angle-right" } }),
      _vm.buttonUrl
        ? _c("AtomButtonLink", {
            attrs: {
              label: "" + _vm.buttonText,
              href: "" + _vm.buttonUrl,
              icon: "angle-right",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }