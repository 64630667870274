var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MoleculeContentSlider",
    _vm._l(_vm.sliderContentData, function (sliderContent, index) {
      return _c(
        "AtomContentSlider",
        { key: index },
        [
          _c("p", [_vm._v(_vm._s(sliderContent.title))]),
          _c("span", { staticClass: "verticale-line-white" }),
          _vm._l(sliderContent.lines, function (line, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(line))])
          }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }