<template>
  <div class="molecule-title-and-description">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeTitleAndDescription"
}
</script>

<style lang="scss" scoped>
  .molecule-title-and-description {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -1.75rem;
    @media only screen and (max-width: 767px) {
      margin: 0;
    }
  }
</style>