<template>
  <div
    :class="{
      section: true,
      'section-partners': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle
        color2="#000"
        :title="titleString"
        :subtitle="subtitleString"
      />
      <AtomVerticalLine color="#2E284F" />
      <AtomContentText
        color="#000"
        text="Growth at scale is possible through smart partnerships with innovative companies:"
      />
    </div>
    <OrganismPartner :partner-data="data" />
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import AtomVerticalLine from "../../../components/atoms/common/AtomVerticalLine";
import AtomContentText from "../../../components/atoms/common/AtomContentText";
import OrganismPartner from "../../../components/organisms/portfolio/OrganismPartner";
export default {
  name: "SectionPartners",
  components: { OrganismPartner, AtomContentText, AtomVerticalLine, AtomTitle },
  props: {
    data: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
    title: {
      required: false,
      type: String,
      default: "",
    },
    subtitle: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    titleString() {
      return this.title || "They Already";
    },
    subtitleString() {
      return this.subtitle || "Work with us";
    },
  },
};
</script>

<style lang="scss" scoped>
.section-partners {
  padding: 9.688rem 5.625rem 6.563rem;
  position: relative;
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 30px 20px;
  }
  .top-title {
    margin-bottom: 3.125rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
    .atom-title {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    .atom-vertical-line {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .atom-content-text {
      max-width: 44.563rem;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
}
</style>
